import { Button, Form, Modal, notification, Spin } from "antd";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormTextFormField } from "../../elements";
import { FileUploadInput } from "../../components";
import { X } from "@phosphor-icons/react";
import { CustomLayerService } from "../../utility";

const UploadSchema = yup.object().shape({
  layerName: yup.string().trim().required("Layer Name is required"),
});

export const LayerUploadModal = ({ open, closeModal }) => {
  const [processing, setProcessing] = useState("");
  const [files, setFiles] = useState([]);

  const {
    control,
    formState: { errors, isValid },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(UploadSchema),
  });

  useEffect(() => {
    reset();
  }, [open, reset]);

  const save = async (userFormData) => {
    try {
      if (files.length === 0) {
        notification.error({ message: "Upload CSV File to continue" });
        return;
      }

      const formData = new FormData();

      // Append the file to the FormData object
      formData.append("file", files[0].file);
      formData.append("layerName", userFormData.layerName);
      setProcessing("Processing");
      await CustomLayerService.upload(formData);
      notification.success({ message: "Layer uploaded successfully" });
      closeModal(true);
    } catch (error) {
      notification.error({ message: error?.message || "Something went wrong" });
    } finally {
      setProcessing("");
    }
  };

  return (
    <Modal
      open={open}
      title="Upload Layers"
      closeIcon={<X size={16} weight="bold" />}
      onCancel={() => closeModal()}
      footer={[
        <Button
          key="submit"
          type="primary"
          disabled={!isValid}
          onClick={handleSubmit(save)}
          block
        >
          Submit
        </Button>,
      ]}
    >
      <Form layout="vertical">
        <Spin spinning={processing} fullscreen />
        <div className="row g-3">
          <div className="col-12">
            <FormTextFormField
              control={control}
              errors={errors?.layerName}
              name={"layerName"}
              required
              label={"Layer Name"}
            />
          </div>
          <div className="col-12">
            <FileUploadInput
              labelText="CSV File"
              files={files}
              setFiles={setFiles}
              maxFiles={1}
            />
          </div>
        </div>
      </Form>
    </Modal>
  );
};
