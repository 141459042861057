import { useEffect, useState } from "react";
import { GeoJSON } from "react-leaflet";
import { GeoService, MapUtility } from "../../utility";
import { message, Spin } from "antd";
import * as ReactDOMServer from "react-dom/server";
import MarkerClusterGroup from "react-leaflet-cluster";
import styled from "styled-components";
import { CommonUtility } from "../../utility/common";
import axios from "axios";

const Header = styled.div`
  font-weight: bold;
  font-size: 17px;
  padding: 10px 0;
  border-bottom: 1px solid;
  text-align: center;
`;

const SmallHeader = styled.div`
  font-size: 14px;
  padding-bottom: 10px;
  padding-top: 5px;
  text-align: center;
  opacity: 0.7;
`;

const PopupContainer = styled.div`
  min-width: 250px;
  width: 100%;
  max-height: 400px;
  overflow-y: auto;

  p {
    font-size: 90%;
    margin: 0.5rem 0;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow: hidden;
`;

const TableRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;

  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const TableData = styled.div`
  width: 50%;
  text-align: left;
  word-wrap: break-word;
  &:first-child {
    font-weight: bold;
  }
`;

export const LayerRendering = ({ layer, bbox, zoom }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    const fetchData = async (cancelToken) => {
      try {
        if (layer.zoom > zoom) {
          message.warning(
            `Unable to load data for the current zoom level: ${zoom}. Please zoom in to at least level: ${layer.zoom} to view the data.`,
          );
          return;
        }
        setData(null);
        setLoading(true);
        const response = await GeoService.layer(
          layer.key,
          {
            bbox: bbox.join(","),
            zoom,
          },
          cancelToken.token,
        );
        setLoading(false);
        setData(response);
      } catch (error) {
        if (error?.code) {
          setLoading(false);
        }
      }
    };
    if (layer.checked && bbox) {
      const request = axios.CancelToken.source();
      fetchData(request);

      return () => {
        if (typeof request !== typeof undefined) {
          request.cancel();
        }
      };
    }
  }, [layer, bbox, zoom]);

  const renderValue = (value) => {
    if (typeof value === "object") {
      return "";
    }
    return value;
  };

  const Popup = ({ properties }) => {
    return (
      <PopupContainer>
        <div>
          <Header>Name: {properties.name || properties["Store number"]}</Header>
          <SmallHeader>(Layer: {layer.name})</SmallHeader>
        </div>
        <div>
          <TableContainer>
            {Object.keys(properties).map((key) => (
              <TableRow>
                <TableData>{CommonUtility.toTitleCase(key)}</TableData>
                <TableData>{renderValue(properties[key])}</TableData>
              </TableRow>
            ))}
          </TableContainer>
        </div>
      </PopupContainer>
    );
  };

  const [hoveredFeature, setHoveredFeature] = useState(null);

  const onEachFeature = (feature, layer) => {
    layer.on({
      mouseover: () => {
        setHoveredFeature(feature);
      },
      mouseout: () => {
        setHoveredFeature(null);
      },
      click: () => {
        setHoveredFeature(feature);
        const popupContent = ReactDOMServer.renderToString(
          <Popup properties={feature.properties || {}} />,
        );
        layer.bindPopup(popupContent).openPopup();
      },
    });
  };

  const style = (feature) => ({
    weight: hoveredFeature === feature ? 3 : 1.7,
    color: layer.color || "#3388ff",
  });

  const pointToLayer = (_, latlng) => {
    const L = require("leaflet");
    const icon = L.icon({
      iconUrl: MapUtility.getSvgBasedOnColor(layer.color),
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
    });
    return L.marker(latlng, { icon });
  };

  return (
    <>
      <Spin spinning={loading} fullscreen />
      {data && (
        <>
          {layer.cluster ? (
            <MarkerClusterGroup chunkedLoading>
              <GeoJSON
                data={data}
                onEachFeature={onEachFeature}
                pointToLayer={pointToLayer}
              />
            </MarkerClusterGroup>
          ) : (
            <GeoJSON data={data} style={style} onEachFeature={onEachFeature} />
          )}
        </>
      )}
    </>
  );
};
