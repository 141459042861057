import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MapScreen } from "./screens/Map";
import { GlobalStyles } from "./global";

function App() {
  return (
    <ThemeProvider theme={{}}>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route index element={<MapScreen />} />
          {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
