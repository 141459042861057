import { APIPath } from "../constant";
import { CRUDService } from "./crud";

class Substations extends CRUDService {
  constructor() {
    super(APIPath.substations);
  }
}
const SubstationsService = new Substations();
Object.freeze(SubstationsService);
export { SubstationsService };
