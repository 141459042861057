import { Layout, message } from "antd";
import {
  LayerGroup,
  LayersControl,
  MapContainer,
  TileLayer,
  useMap,
} from "react-leaflet";
import styled from "styled-components";
import { GetGeoLayersData } from "../hooks/map";
import { useEffect, useState } from "react";
import { SearchInput } from "../hooks/search";
import {
  BrowserUtility,
  DefaultMapCenter,
  DefaultZoom,
  StorageConstant,
} from "../utility";
import { DynamicLayers } from "../page-components/map/DynamicLayer";
import { LayersLegendBox } from "../page-components/map/Legend";
import { DrawerBox } from "../page-components/map/DrawerBox";

const { Content } = Layout;

const Container = styled.div`
  height: 100vh;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  overflow-x: hidden;

  .map {
    height: 100%;
    max-width: 100%;
  }
`;

const SearchInputWrapper = styled.div`
  position: absolute;
  top: 0px;
  width: 50%;
  z-index: 1000;
  // padding-inline: 80px;
  left: 10%;
`;

const MapContentContainer = styled(Content)`
  position: relative;
  width: 100%;
  height: 100vh;
`;

const ChangeView = ({ center, zoom }) => {
  const map = useMap();
  useEffect(() => {
    if (
      DefaultMapCenter[0] === center?.[0] &&
      DefaultMapCenter[1] === center?.[1]
    ) {
      // map.setView(DefaultMapCenter, zoom);
    }
  }, [map, center, zoom]);

  return null;
};

export const MapScreen = () => {
  const [mapLocationGeoMetry, setmapLocationGeoMetry] =
    useState(DefaultMapCenter);
  const [query, setQuery] = useState("");
  const [zoom, setZoom] = useState(DefaultZoom);
  const [defaultLayers, setDefaultLayers] = useState([]);
  const {
    filteredData: layers,
    filterChanged,
    filter,
    refreshData,
  } = GetGeoLayersData();

  useEffect(() => {
    const defaultLayersList = BrowserUtility.get(StorageConstant.Layers) || "";
    setDefaultLayers(
      layers.map((ele) => ({
        ...ele,
        checked: defaultLayersList.includes(ele.key),
      })),
    );
  }, [layers]);

  useEffect(() => {
    const prevCenter = BrowserUtility.getObj(StorageConstant.mapCenter);
    if (prevCenter && prevCenter?.length) {
      setmapLocationGeoMetry(prevCenter);
    } else {
      setmapLocationGeoMetry(DefaultMapCenter);
    }
    const preZoom = +BrowserUtility.get(StorageConstant.zoom);
    if (preZoom) {
      setZoom(preZoom || DefaultZoom);
    }
  }, []);

  useEffect(() => {
    const L = require("leaflet");
    delete L.Icon.Default.prototype._getIconUrl;
    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }, []);

  const onLayerChange = (e, key) => {
    e.stopPropagation();
    const list = defaultLayers.map((layer) => {
      if (layer.key === key) {
        return {
          ...layer,
          checked: !layer.checked,
        };
      }
      return layer;
    });
    setDefaultLayers(list);
  };

  const onSaveLayer = () => {
    BrowserUtility.saveObj(StorageConstant.Layers, [
      ...defaultLayers.filter((ele) => ele.checked).map((ele) => ele.key),
    ]);
    message.success(`Layers saved`);
  };

  // const getRealTimeCongestionData = async (item) => {
  //   try {
  //     setRealTimeCongestionData(null);
  //     setLoading(true);
  //     // const
  //     const result = await CongestionsService.realTime(
  //       item.id,
  //       "5-22-2024",
  //       DateUtility.dateToString(new Date(), DateFormat.date)
  //     );
  //     if (result.length > 0) {
  //       const total = result.reduce((a, b) => a + b.congestion_price_rt, 0);
  //       setRealTimeCongestionData({
  //         ...result[result.length - 1],
  //         avg: total / result.length,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const clearMap = () => {
    filterChanged({ search: "" });
    setDefaultLayers(layers.map((ele) => ({ ...ele, checked: false })));
    setmapLocationGeoMetry(DefaultMapCenter);
    setQuery("");
    setZoom(DefaultZoom);
    BrowserUtility.remove(StorageConstant.Layers);
    BrowserUtility.saveObj(StorageConstant.mapCenter, DefaultMapCenter);
    BrowserUtility.save(StorageConstant.zoom, DefaultZoom);
  };

  const onLocationGeoMetryChange = (item) => {
    if (item) {
      setmapLocationGeoMetry([item.y, item.x]);
    }
  };

  return (
    <Layout>
      <MapContentContainer>
        <Container>
          <MapContainer
            center={mapLocationGeoMetry}
            zoom={zoom}
            className="map"
          >
            <DrawerBox
              filter={filter}
              filterChanged={filterChanged}
              onLayerChange={onLayerChange}
              defaultLayers={defaultLayers}
              onSaveLayer={onSaveLayer}
              clearMap={clearMap}
              refreshData={refreshData}
            />
            <SearchInputWrapper>
              <SearchInput
                query={query}
                setQuery={setQuery}
                zoom={zoom}
                onLocationGeoMetryChange={onLocationGeoMetryChange}
              />
            </SearchInputWrapper>
            <ChangeView center={mapLocationGeoMetry} zoom={zoom} />
            <LayersControl>
              <LayersControl.BaseLayer name="Base" checked>
                <TileLayer
                  url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
                  attribution="&copy; <a href=http://osm.org/copyright>OpenStreetMap</a> contributors"
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Google Map">
                <TileLayer
                  attribution="Google Maps"
                  url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Google Map Satellite">
                <LayerGroup>
                  <TileLayer
                    attribution="Google Maps Satellite"
                    url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                  />
                  <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}" />
                </LayerGroup>
              </LayersControl.BaseLayer>
              <DynamicLayers
                layers={defaultLayers}
                zoom={zoom}
                center={mapLocationGeoMetry}
                setZoom={setZoom}
                setmapLocationGeoMetry={setmapLocationGeoMetry}
              />
            </LayersControl>
            <LayersLegendBox zoom={zoom} layers={defaultLayers} />
          </MapContainer>
        </Container>
      </MapContentContainer>
    </Layout>
  );
};
