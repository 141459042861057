export class MapUtility {
  static isNotEmpty(item) {
    return (
      item !== undefined && item !== null && item !== "" && item.length !== 0
    );
  }

  static getCongestionColor(congestionLevel) {
    if (congestionLevel === null || congestionLevel < 0) {
      congestionLevel = 0;
    }
    congestionLevel = congestionLevel / 100;
    // Map congestion level to a hue value between 0 and 360
    var hue = Math.round(congestionLevel * 360);
    // Convert HSL to RGB
    const [r, g, b] = MapUtility.hslToRgb(hue, 0.8, 0.5);
    return `rgb(${r}, ${g}, ${b})`;
  }

  static hslToRgb(h, s, l) {
    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      };

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  }

  static getLngLatFromGooglePlace = async (id) => {
    const geocoder = new window.google.maps.Geocoder();
    let lnlat = {};
    await geocoder.geocode({ placeId: id }, async (results, status) => {
      if (status === "OK" && results[0]) {
        const location = results[0]?.geometry?.location;
        const geometry =
          results[0].geometry.bounds || results[0].geometry.viewport;
        lnlat = {
          label: results[0]?.formatted_address,
          lat: location.lat(),
          lng: location.lng(),
          bounds: geometry
            ? {
                north: geometry.getNorthEast().lat(),
                east: geometry.getNorthEast().lng(),
                south: geometry.getSouthWest().lat(),
                west: geometry.getSouthWest().lng(),
              }
            : null,
        };
      }
    });
    return lnlat;
  };

  static getVoltageColor = (item) => {
    const voltage = item["Voltage (kV)"];
    if (voltage <= 70) {
      return "green";
    } else if (voltage <= 100) {
      return "yellow";
    } else if (voltage <= 120) {
      return "orange";
    }
    return "red";
  };

  static getSvgBasedOnColor = (color) => {
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" width="400" height="400" viewBox="0 0 400 400" fill="none">
  <circle cx="200" cy="200" r="100" fill="${color}" fill-opacity="0.15" stroke="${color}" stroke-width="50"/>
</svg>`;
    return `data:image/svg+xml;base64,${btoa(svg)}`;
  };
}
