import axios from "axios";
import { APIPath } from "../constant";

const onSuccess = (response) => response.data;

const onError = async (error) =>
  Promise.reject({
    ...(error?.response?.data || {}),
  });

const request = async (options, isSecure, cancelToken) => {
  const headers = {};

  headers["Access-Control-Allow-Origin"] = "*";

  const client = axios.create({
    baseURL: APIPath.server,
    headers: { ...headers },
    cancelToken,
  });

  return client(options).then(onSuccess).catch(onError);
};

const uploadRequest = async (options, isSecure, cancelToken) => {
  const headers = {};

  headers["Access-Control-Allow-Origin"] = "*";
  headers["Content-Type"] = "multipart/form-data";

  const client = axios.create({
    baseURL: APIPath.server,
    headers: { ...headers },
    cancelToken,
  });

  return client(options).then(onSuccess).catch(onError);
};

const uploadFiles = (url, data, headers) => {
  const client = axios({
    url,
    method: "PUT",
    headers: { ...headers },
    data,
  });

  return client.then(onSuccess).catch(onError);
};

export class BaseService {
  static get(url, isSecure = true, cancelToken) {
    return request(
      {
        url,
        method: "GET",
      },
      isSecure,
      cancelToken,
    );
  }

  static post(url, data, isSecure = true) {
    return request(
      {
        url,
        method: "POST",
        data,
      },
      isSecure,
    );
  }

  static uploadFile(url, data, isSecure = true) {
    return request(
      {
        url,
        method: "POST",
        data,
      },
      isSecure,
    );
  }

  static put(url, data, isSecure = true) {
    return request(
      {
        url,
        method: "PUT",
        data,
      },
      isSecure,
    );
  }

  static patch(url, data, isSecure = true) {
    return request(
      {
        url,
        method: "PATCH",
        data,
      },
      isSecure,
    );
  }

  static extenralAPICall(url) {
    const client = axios({
      url,
      method: "GET",
      timeout: 1000 * 3,
    });
    return client.then(onSuccess).catch(onError);
  }

  static remove(url, isSecure = true) {
    return request(
      {
        url,
        method: "DELETE",
      },
      isSecure,
    );
  }

  static upload = (url, data, header) => {
    return uploadFiles(url, data, header);
  };
}
