import { useCallback, useRef } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { Trash, UploadSimple } from "@phosphor-icons/react";
import { Button, notification } from "antd";

const KILO_BYTES_PER_BYTE = 1000;

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const DropContainer = styled.div`
  padding: 43.5px 40px;
  transition: border 0.24s ease-in-out;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #d9d9d9;
`;

const FilePreviewContainer = styled.article`
  max-height: 300px;
  overflow-y: auto;
`;

const PreviewContainer = styled.div`
  /* padding: 0.25rem; */
`;

const ImageContainer = styled.div`
  padding: 10px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    cursor: pointer;
  }
`;

const FileUploadDropContainer = styled(DropContainer)`
  background: transparent !important;
`;

const DragText = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0em;
  margin-bottom: 0px;
  display: flex;
  span {
    margin-left: 4px;
  }
  svg {
    margin-right: 12px;
  }
`;

const PreviewFile = ({ file, removeFile }) => {
  return (
    <PreviewContainer className="mt-2 mt-md-3 col-12">
      <ImageContainer>
        <span>{file.name}</span>
        <aside>
          {file.file && (
            <span className="me-2">{convertBytesToKB(file.file.size)} kb</span>
          )}
          <Trash className="icon" onClick={(e) => removeFile(e, file)} />
        </aside>
      </ImageContainer>
    </PreviewContainer>
  );
};

export const PreivewFiles = ({ files, removeFile }) => {
  return (
    <FilePreviewContainer className="row gx-2 gx-md-3">
      {files.map((file) => (
        <PreviewFile
          file={file}
          removeFile={removeFile}
          key={file.name || file.key}
        />
      ))}
    </FilePreviewContainer>
  );
};

export const UploadedFileList = ({ files, removeFile }) => {
  return (
    <>
      {files.length > 0 && (
        <PreivewFiles files={files} removeFile={removeFile} />
      )}
    </>
  );
};

export function FileUploadInput({
  accept,
  maxFiles,
  maxFileSize,
  files,
  setFiles,
  onlyButton,
  labelText,
  removeURLFile,
  hideFiles,
}) {
  const fileInputRef = useRef();

  const addNewFiles = (newFiles) => {
    if ((maxFiles || 0) === 1) {
      if (!maxFileSize || newFiles[0].size / 1048576 <= maxFileSize) {
        setFiles([
          {
            name: newFiles[0].name,
            file: newFiles[0],
          },
        ]);
      } else {
        notification.info({
          message: `Upload file less than ${maxFileSize} MB`,
        });
      }
    } else {
      const temp = [];
      let hasMaxSize = false;
      newFiles.forEach((file) => {
        if (!maxFileSize || file.size / 1048576 <= maxFileSize) {
          // in MB
          if (!maxFiles || files.length < maxFiles) {
            temp.push({
              name: file.name,
              file,
            });
          }
        } else {
          hasMaxSize = true;
        }
      });
      setFiles((x) => [...x, ...temp]);
      if (hasMaxSize) {
        notification.info({
          message: `Upload file less than ${maxFileSize} MB`,
        });
      }
    }
    fileInputRef.current.getElementsByTagName("input")[0].value = null;
  };

  const onDrop = useCallback((acceptedFiles) => {
    addNewFiles(acceptedFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    accept,
    maxFiles,
    multiple: !maxFiles || maxFiles > 1,
    noClick: onlyButton,
    noKeyboard: onlyButton,
    onDrop,
  });

  const removeFile = (e, file) => {
    e.preventDefault();
    e.stopPropagation();
    if (file.file) {
      setFiles(files.filter((x) => x.name !== file.name));
    } else {
      removeURLFile(file);
    }
  };

  return (
    <>
      {labelText && <label className="mb-2">{labelText}</label>}
      {onlyButton ? (
        <div {...getRootProps({})} ref={fileInputRef}>
          <input {...getInputProps()} />
          <Button onClick={open}>Upload</Button>
        </div>
      ) : (
        <FileUploadDropContainer
          {...getRootProps({
            isDragActive,
            isDragAccept,
            isDragReject,
          })}
          ref={fileInputRef}
        >
          <input {...getInputProps()} />
          <DragText>
            <UploadSimple size={16} weight="bold" />
            Choose a file <span>or drag it here.</span>
          </DragText>
        </FileUploadDropContainer>
      )}
      {!hideFiles && (
        <UploadedFileList files={files || []} removeFile={removeFile} />
      )}
    </>
  );
}
