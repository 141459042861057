import { useEffect, useMemo, useState } from "react";
import { GeoService, SubstationsService } from "../utility";
import axios from "axios";

export const GetTransmissionData = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("data/transmission_line.json");

        const tempData = response.data.map((x) => {
          const keys = Object.keys(x);

          const substationKeys = keys.filter((x) => x.includes("Substation"));

          const substations = substationKeys.map((key, index) => {
            const temp = {
              key,
              value: x[key],
              subId: x[`Sub ${index + 1} ID`],
              shapeLength: x[`Shape Length${index > 0 ? `.${index}` : ""}`],
            };
            return temp;
          });

          const coordinates = x.Geometry.coordinates[0].map((item) => [
            item[1],
            item[0],
          ]);

          return {
            ...x,
            coordinates,
            substations,
          };
        });

        setData(tempData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return { data };
};

export const GetSubstationData = () => {
  const [data, setData] = useState([]);

  const mergedData = useMemo(
    () => data.filter((x) => !!x.congestion_price_rt),
    [data],
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await SubstationsService.get();
        setData(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return { data, mergedData };
};

export const GetTransmissionLinesData = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("data/transmission_line.json");

        const tempData = response.data.map((x) => {
          const keys = Object.keys(x);

          const substationKeys = keys.filter((x) => x.includes("Substation"));

          const substations = substationKeys.map((key, index) => {
            const temp = {
              key,
              value: x[key],
              subId: x[`Sub ${index + 1} ID`],
              shapeLength: x[`Shape Length${index > 0 ? `.${index}` : ""}`],
            };
            return temp;
          });

          const coordinates = x.Geometry.coordinates[0].map((item) => [
            item[1],
            item[0],
          ]);

          return {
            ...x,
            coordinates,
            substations,
          };
        });

        setData(tempData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return { data };
};

export const GetGeoLayersData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    search: "",
  });
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await GeoService.get(filter);
        setData(response);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [refresh]);

  const filterChanged = (value) => {
    setFilter({
      ...filter,
      ...value,
    });
  };

  const filteredData = useMemo(() => {
    const search = filter.search || "";
    return data.filter(
      (x) => !search || x.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [data, filter]);

  const refreshData = () => {
    setRefresh(Math.random());
  };

  return {
    filteredData,
    loading,
    setData,
    filterChanged,
    filter,
    refreshData,
  };
};
