import { APIPath } from "../constant";
import { BaseService } from "./base";
import { CRUDService } from "./crud";

class Congestions extends CRUDService {
  constructor() {
    super(APIPath.congestions);
  }

  realTime(id, date) {
    return BaseService.get(`${APIPath.congestionsRealTime}/${id}/${date}`);
  }
}
const CongestionsService = new Congestions();
Object.freeze(CongestionsService);
export { CongestionsService };
