/* eslint-disable react-hooks/exhaustive-deps */
import { AutoComplete, Input } from "antd";
import { GeoSearchControl, GoogleProvider } from "leaflet-geosearch";
import { useEffect, useMemo, useState } from "react";
import { useMap } from "react-leaflet";
import styled from "styled-components";
import { DefaultZoom, MapUtility } from "../utility";
const SearchContainer = styled.div`
  .pointer {
    cursor: pointer;
  }
  .ant-select {
    width: 100%;
    font: 16px arial, sans-serif;
    line-height: 48px !important;
    height: 48px !important;
  }
  position: relative;
  margin: 12px 0;
`;
export const SearchField = () => {
  const provider = new GoogleProvider({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  // @ts-ignore
  const searchControl = new GeoSearchControl({
    provider: provider,
    showMarker: false,
  });

  const map = useMap();

  useEffect(() => {
    map.addControl(searchControl);
    return () => map.removeControl(searchControl);
  }, []);

  return null;
};

export const SearchInput = ({ onLocationGeoMetryChange, query, setQuery }) => {
  const [results, setResults] = useState([]);
  const map = useMap();

  const options = useMemo(
    () =>
      query?.length > 3
        ? results?.map((ele) => ({
            value: `${ele.description}-${ele.placeId}`,
            label: ele.description,
            placeId: ele.place_id,
            ...ele,
          }))
        : [],
    [query, results]
  );

  const onSelect = async (value) => {
    const selectedPlace = options.find((ele) => ele.value === value);
    if (selectedPlace) {
      const result = await MapUtility.getLngLatFromGooglePlace(
        selectedPlace.placeId
      );
      if (result) {
        onLocationGeoMetryChange({
          label: result.label,
          lat: result.lat,
          lng: result.lng,
        });

        if (result.bounds) {
          map.fitBounds([
            [result.bounds.south, result.bounds.west],
            [result.bounds.north, result.bounds.east],
          ]);
          map.setView([result.lat, result.lng]);
        } else {
          map.setView([result.lat, result.lng]);
        }
        setQuery(selectedPlace.description);
      }
    }
  };

  useEffect(() => {
    if (query) {
      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions({ input: query }, (predictions) => {
        setResults(predictions || []);
      });
    }
  }, [query]);

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (query) {
        const result = await MapUtility.getLngLatFromGooglePlace(
          results[0].place_id
        );
        if (result) {
          onLocationGeoMetryChange({
            label: result.label,
            lat: result.lat,
            lng: result.lng,
          });
          if (result.bounds) {
            map.setView([result.lat, result.lng], DefaultZoom);
            map.fitBounds([
              [result.bounds.south, result.bounds.west],
              [result.bounds.north, result.bounds.east],
            ]);
          } else {
            map.setView([result.lat, result.lng], DefaultZoom);
          }
          setQuery(results[0].description);
        }
      }
    }
  };

  return (
    <SearchContainer className="">
      <form onSubmit={handleKeyDown}>
        <AutoComplete
          options={options}
          onSearch={(e) => setQuery(e)}
          placeholder="Search location"
          value={query}
          onSelect={onSelect}
          onKeyDown={handleKeyDown}
          allowClear
        />
      </form>
    </SearchContainer>
  );
};

export const SearchInputLayers = ({ onSearch, value }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <div className="col">
      <form onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="Search Map Layer"
          value={value}
          onChange={(e) => onSearch(e.target.value)}
          allowClear
        />
      </form>
    </div>
  );
};
