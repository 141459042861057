import { APIPath } from "../constant";
import { BaseService } from "./base";
import { CRUDService } from "./crud";

class CustomLayer extends CRUDService {
  constructor() {
    super(APIPath.customLayers);
  }

  upload(data) {
    return BaseService.uploadFile(APIPath.customLayerUpload, data);
  }
}
const CustomLayerService = new CustomLayer();
Object.freeze(CustomLayerService);
export { CustomLayerService };
