import { CommonUtility } from "../common";
import { APIPath } from "../constant";
import { BaseService } from "./base";
import { CRUDService } from "./crud";

class Geo extends CRUDService {
  constructor() {
    super(APIPath.geoLayers);
  }

  layer(url, query, cancelToken) {
    const params = CommonUtility.objectToParams(query);
    return BaseService.get(
      `${APIPath.geoLayers}/${url}?${params}`,
      true,
      cancelToken,
    );
  }
}
const GeoService = new Geo();
Object.freeze(GeoService);
export { GeoService };
