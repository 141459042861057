import { Button, Checkbox, Drawer, Menu } from "antd";
import { useMap } from "react-leaflet";
import styled from "styled-components";
import { useMemo, useState } from "react";
import { Typography } from "antd";
import {
  BrowserUtility,
  DefaultMapCenter,
  StorageConstant,
  CommonUtility,
} from "../../utility";
import { CaretRight } from "@phosphor-icons/react";
import { SearchInputLayers } from "../../hooks/search";
import { LayerUploadModal } from "./LayerUploadModal";

const { Title } = Typography;

const LogoHeader = styled.div`
  display: flex;
  align-items: center;
  background: white !important;
  gap: 8px;
  img {
    width: 40px;
  }
  h2 {
    margin: 0px !important;
  }
`;

const MapSider = styled.div`
  background: white !important;
  .ant-layout-sider-trigger {
    background: white !important;
    display: none;
  }
  .ant-menu-item {
    height: auto !important;
    text-wrap: wrap;
  }
  height: 100%;
  position: relative;
`;

const SideBarBox = styled.div`
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 990;

  button {
    height: auto;
  }
`;

const UploadButton = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Scroller = styled.div`
  height: calc(100vh - 180px);
  overflow: scroll;
`;

export const DrawerBox = ({
  filterChanged,
  onLayerChange,
  defaultLayers,
  clearMap,
  onSaveLayer,
  filter,
  refreshData,
}) => {
  const [drawer, setDrawer] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const map = useMap();
  const onClear = () => {
    map.setView(DefaultMapCenter, 8);
    clearMap();
  };
  const saveLayer = () => {
    const mapCenter = map.getCenter();
    if (mapCenter) {
      BrowserUtility.saveObj(StorageConstant.mapCenter, [
        mapCenter.lat,
        mapCenter.lng,
      ]);
    }
    BrowserUtility.save(StorageConstant.zoom, map.getZoom());
    onSaveLayer();
  };

  const { items, defaultOpenKeys } = useMemo(() => {
    const grouped = CommonUtility.groupedData(defaultLayers, "category");
    const defaultOpenKeys = Object.keys(grouped).filter(
      (ele) => grouped[ele].some((ele) => ele.checked) && ele,
    );
    const items = Object.keys(grouped).map((ele) => {
      const subCategoryLayers = CommonUtility.groupedData(
        grouped[ele],
        "subCategory",
      );

      return {
        key: ele,
        label: ele,
        children: Object.keys(subCategoryLayers).map((subCategory) => ({
          key: subCategory || "Other",
          label: subCategory || "Other",
          type: "group",

          children: subCategoryLayers[subCategory].map((item) => ({
            key: item.name,
            label: (
              <Checkbox
                checked={item.checked}
                onChange={(e) => onLayerChange(e, item.key)}
              >
                {item.name}
              </Checkbox>
            ),
            ...item,
          })),
        })),
      };
    });
    return { items, defaultOpenKeys };
  }, [defaultLayers, onLayerChange]);

  const closeMddal = (result) => {
    if (result) {
      refreshData();
    }
    setOpenModel(false);
  };

  return (
    <>
      <Drawer
        placement="left"
        className="StatmentDrawer"
        onClose={() => {
          setDrawer(false);
        }}
        open={drawer}
        title={
          <LogoHeader>
            <div className="d-flex col-12 justify-content-between align-items-center">
              <div className="d-flex  align-items-center">
                <img alt="Juicee logo" src="./juicee-logo.png" />
                &nbsp;&nbsp; &nbsp;&nbsp;<Title level={2}>Juicee</Title>
              </div>{" "}
            </div>
          </LogoHeader>
        }
      >
        <MapSider>
          <Scroller>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <Title level={3}>Map Layers</Title>{" "}
              <Button className="mb-3" onClick={saveLayer}>
                Save Layers
              </Button>
            </div>

            <div className="d-flex justify-content-between align-items-center mb-3">
              <SearchInputLayers
                value={filter.search}
                onSearch={(search) => filterChanged({ search })}
              />
              <Button className="ms-2" onClick={onClear}>
                Reset
              </Button>
            </div>
            <Menu
              mode="inline"
              items={items}
              defaultOpenKeys={defaultOpenKeys}
            />
          </Scroller>

          <UploadButton>
            <Button type="primary" block onClick={() => setOpenModel(true)}>
              Upload Layer
            </Button>
          </UploadButton>
        </MapSider>
      </Drawer>
      <SideBarBox>
        <Button onClick={() => setDrawer(true)}>
          <CaretRight size={28} />
        </Button>
      </SideBarBox>
      <LayerUploadModal closeModal={closeMddal} open={openModel} />
    </>
  );
};
