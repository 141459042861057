import { Form, Input } from "antd";
import { Controller } from "react-hook-form";

export function FormTextFormField({
  control,
  name,
  defaultValue = "",
  placeholder,
  required,
  errors,
  label,
  type,
  hint,
  tooltip,
  extraLabel,
  ...rest
}) {
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={(props) => (
        <Form.Item
          label={label}
          tooltip={tooltip}
          rules={[{ required, message: errors?.message }]}
          className={`mb-3 mb-md-4 ${errors?.message && "error"}`}
        >
          <Input
            placeholder={placeholder || label}
            value={props.field.value}
            type={type}
            onChange={(e) => props.field.onChange(e.target.value)}
            {...rest}
          />
        </Form.Item>
      )}
    />
  );
}
