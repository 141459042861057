import { memo, useEffect, useState } from "react";
import { LayersControl, useMap } from "react-leaflet";
import { BrowserUtility, StorageConstant } from "../../utility";
import { LayerRendering } from "./LayerMap";
import { debounce } from "lodash";

const getBounds = (bounds) => {
  return [
    bounds.getWest(),
    bounds.getSouth(),
    bounds.getEast(),
    bounds.getNorth(),
  ];
};

const isBoundsWithin = (newBounds, currentBounds) => {
  // Logic to check if newBounds is within currentBounds
  return (
    newBounds[0] >= currentBounds[0] && // West
    newBounds[1] >= currentBounds[1] && // South
    newBounds[2] <= currentBounds[2] && // East
    newBounds[3] <= currentBounds[3] // North
  );
};

export const DynamicLayers = memo(({ layers, zoom, setZoom }) => {
  const map = useMap();
  const [bbox, setBbox] = useState(getBounds(map.getBounds()));

  const handleDebounce = debounce(async () => {
    if (map) {
      const bounds = map.getBounds();
      const tempBounds = getBounds(bounds);
      if (
        tempBounds.toString() !== bbox.toString() &&
        !isBoundsWithin(tempBounds, bbox)
      ) {
        setBbox(getBounds(bounds));
      }
      setZoom(map.getZoom());
    }
  }, 1000);

  useEffect(() => {
    const onMoveEnd = () => {
      handleDebounce();
    };
    map.on("moveend", onMoveEnd);
    return () => {
      map.off("moveend", onMoveEnd);
    };
  }, [map, handleDebounce]);

  useEffect(() => {
    const prevCenter = BrowserUtility.getObj(StorageConstant.mapCenter);
    const preZoom = +BrowserUtility.get(StorageConstant.zoom);
    if (prevCenter && prevCenter?.length) {
      map.setView(prevCenter, preZoom);
    }
  }, [map]);

  return (
    <>
      {layers.map((layer, index) => (
        <LayersControl.Overlay
          key={index}
          checked={layer.checked}
          name={layer.name}
        >
          <LayerRendering layer={layer} bbox={bbox} zoom={zoom} />
        </LayersControl.Overlay>
      ))}
    </>
  );
});
