const basePath = process.env.REACT_APP_API_PATH;
export const APIPath = {
  server: `${basePath}/api`,

  congestions: "congestions",
  congestionsRealTime: "congestions/real-time",
  substations: "substations",
  geoLayers: "geo-layers",
  customLayers: "custom-layers",
  customLayerUpload: "custom-layers/upload",
};
