import { useMemo } from "react";
import Draggable from "react-draggable";
import styled from "styled-components";

const LegendBox = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  background: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  font-size: 12px;
  line-height: 1.5;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const LegendColorBox = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-right: 8px;
  background: ${(props) => props.color};
`;

export const LayersLegendBox = ({ zoom, layers }) => {
  const checkedLayers = useMemo(
    () => layers.filter((ele) => ele.checked),
    [layers],
  );
  return (
    <Draggable>
      <LegendBox>
        <LegendItem style={{ fontSize: "0.8rem" }}>
          <b>Current Zoom Level: {zoom}</b>
        </LegendItem>
        {checkedLayers.map((ele) => (
          <LegendItem key={ele.name}>
            <LegendColorBox color={ele.color} />
            <span>
              {ele.name} (&gt;&nbsp;{ele.zoom} zoom)
            </span>
          </LegendItem>
        ))}
      </LegendBox>
    </Draggable>
  );
};
